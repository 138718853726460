<!--
 * @Author: lbh
 * @Date: 2022-06-15 11:54:16
 * @LastEditors: lbh
 * @LastEditTime: 2024-03-06 10:09:24
 * @Description: file content
-->
<template>
  <div class="edit-box">
    <!-- 左邊的 組件 框 -->
    <div
      class="px-left-menu"
      @click="onLeft.drawer = true"
    >
      <i class="el-icon-d-arrow-right"></i>
    </div>
    <el-drawer
      :visible.sync="onLeft.drawer"
      direction="ltr"
      :show-close="false"
      size="300px"
      class="px-left-menu-drawer"
    >
      <template #title>
        <div class="title-box">
          <div class="title">選擇組件</div>
          <el-tooltip
            class="item"
            effect="dark"
            content="切換顯示類型"
            placement="right"
          >
            <div
              class="menu"
              @click="onLeft.onLeftShowType = !onLeft.onLeftShowType"
            >
              <i
                v-if="onLeft.onLeftShowType"
                class="el-icon-s-help"
              ></i>
              <i
                v-else
                class="el-icon-help"
              ></i>
            </div>
          </el-tooltip>
        </div>
      </template>
      <el-input
        placeholder="關鍵字搜索"
        v-model="searchValue"
      />
      <draggable
        v-model="defaultComs"
        animation="300"
        @start="onLeftStart"
        @end="onLeftEnd"
        :move="onLeftMove"
        :options="{ group: { name: 'itxst', pull: 'clone' }, sort: true }"
        :class="onLeft.onLeftShowType ? 'tgs_g' : ''"
      >
        <transition-group>
          <div
            v-for="(item, index) in defaultComs"
            :key="`${index}_2`"
            v-show="item.show && item.name.includes(searchValue)"
            class="item-box"
          >
            <el-image
              style="width: 100%"
              :src="item.img"
              class="image"
            ></el-image>
            <div
              v-html="$util.rex.getHtml(item.name)"
              class="name"
            ></div>
          </div>
        </transition-group>
      </draggable>
    </el-drawer>
    <!-- 編輯 -->
    <el-drawer
      :visible.sync="onRight.drawer"
      @close="rightClose"
      :direction="editPosition"
      :modal="false"
      :show-close="false"
      size="400px"
      :close-on-press-escape="false"
      class="right-edit-drawer px-edit-box"
    >
      <template #title>
        <div>修改組件內容</div>
        <template v-if="isMobile">
          <i
            v-if="editPosition == 'btt'"
            class="el-icon-caret-top"
            style="font-size: 20px; cursor: pointer"
            @click="editPosition = 'ttb'"
          ></i>
          <i
            v-else
            class="el-icon-caret-bottom"
            style="font-size: 20px; cursor: pointer"
            @click="editPosition = 'btt'"
          ></i>
        </template>
        <template v-else>
          <i
            v-if="editPosition == 'rtl'"
            class="el-icon-caret-left"
            style="font-size: 20px; cursor: pointer"
            @click="editPosition = 'ltr'"
          ></i>
          <i
            v-else
            class="el-icon-caret-right"
            style="font-size: 20px; cursor: pointer"
            @click="editPosition = 'rtl'"
          ></i>
        </template>
      </template>
      <component
        :is="editComs.component"
        :configs="editComs.configs"
        :type="onRight.active"
        :index="onRight.index"
        :activeTabIndex="onRight.activeTabIndex"
        @setValue="setValue"
        @addItem="addItem"
        @delItem="delItem"
      ></component>
    </el-drawer>
    <!-- 內容體 -->
    <div class="body-box">
      <!-- 頂部編輯欄目 -->
      <div class="px-top-setting">
        <div class="welcome">
          歡迎登錄
          <el-dropdown @command="changeLanguage">
            <span class="el-dropdown-link">
              選擇展示語言-{{nowL.name}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in language"
                :key="item.code"
                :command="item"
              >{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
        <div
          class="save"
          @click="onSave()"
        >保存</div>
        <div
          class="save"
          @click="onMenu"
        >回主頁</div>
      </div>
      <draggable
        v-model="coms"
        group="site"
        animation="100"
        :disabled="isMobile"
      >
        <transition-group>
          <div
            @contextmenu.prevent="onContextmenu"
            @mouseover="onMouseover(index)"
            v-for="(item, index) in coms"
            :key="`${index}_1`"
            class="edit_com_box"
            :style="`${item.key == `suspensionModule` ? 'z-index:2;' : ''}`"
          >
            <component
              :is="item.component"
              @clickItem="clickItem"
              :configs="item.configs"
              :Xindex="index"
              :hasEdit="item.key == editComs.key"
              :lang="nowL.code"
            ></component>
          </div>
        </transition-group>
      </draggable>
    </div>

    <!-- 保存 -->
    <el-dialog
      title="保存頁面"
      :visible.sync="savePage.dialogFormVisible"
    >
      <el-form :model="savePage.form">
        <el-form-item label="頁面類型">
          <el-select
            v-model="savePage.form.type"
            placeholder="請選擇頁面類型"
          >
            <el-option
              label="普通頁"
              value="default"
            ></el-option>
            <el-option
              label="首頁"
              value="menu"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="頁面名稱">
          <el-input
            v-model="savePage.form.pageName"
            autocomplete="off"
            :disabled="savePage.form.type == 'menu'"
            placeholder="請輸入頁面名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="頁面標題">
          <el-input
            v-model="savePage.form.pageTitle"
            autocomplete="off"
            placeholder="請輸入頁面標題"
          ></el-input>
        </el-form-item>
        <el-form-item label="頁面描述">
          <el-input
            v-model="savePage.form.pageDescription"
            autocomplete="off"
            type="textarea"
            rows="3"
            maxlength="300"
            show-word-limit
            placeholder="請輸入頁面描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="頁面關鍵字">
          <el-input
            v-model="savePage.form.pageKeyWords"
            autocomplete="off"
            type="textarea"
            rows="3"
            maxlength="300"
            show-word-limit
            placeholder="請輸入關鍵字,使用[,]隔開"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="savePage.dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addPageFun(1)"
        >保 存</el-button>
        <el-button
          type="success"
          @click="addPageFun(2)"
        >保存並預覽</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getConfig } from '../js/utils/default_config';
import draggable from 'vuedraggable';
import storage from '@/js/utils/storage';

import {
  addPage,
  pageExists,
  updatePage,
  getPageConfigList,
  getHeaderOrFooterList,
} from '../js/api/api';
export default {
  components: {
    draggable,
  },
  data () {
    return {
      searchValue: '',
      // 默認的組件
      defaultComs: [],
      // 組件體
      coms: [],
      // 左邊的組件
      onLeft: {
        drawer: false,
        onLeftShowType: false,
      },
      // 右邊的編輯
      onRight: {
        drawer: false,
        active: '',
        index: 0,
        activeTabIndex: 0,
      },
      editComs: {},
      // 當前選中的
      mouseoverIndex: -1,
      // 保存框
      savePage: {
        dialogFormVisible: false,
        form: {
          type: 'default',
          pageName: '',
          pageTitle: '',
          pageDescription: ''
        },
      },
      pageData: {},
      editPosition: "rtl",
      language: [],
      nowL: {
        code: 'HK',
        name: '繁體'
      }
    };
  },
  computed: {
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
  },
  created () {
    document.body.classList.add('edit')
    let language = this.$storage.get('language')
    this.language = language;
    let now_language = this.$storage.get('now_language')
    if (!now_language) now_language = 'HK';
    for (let i = 0; i < language.length; i += 1) {
      if (language[i].code == now_language) {
        this.nowL = language[i]
        break;
      }
    }
    this.defaultComs = this.$util.object.clone(getConfig());
    // this.getComponent(this.defaultComs[0]);
    // return;

    let arg = this.$route.params.arg;
    if (arg) {
      this.getPageConfigListFun(arg);
      return;
    }
    let isEdit = this.$store.getters.getIsEdit;
    if (!isEdit) {
      this.$router.replace('/');
    }
  },
  methods: {
    // 修改當前語言
    changeLanguage (item) {
      this.$storage.set('now_language', item.code)
      this.nowL = item;
      this.$changeLang.togger();
    },
    getPageConfigListFun (pageName) {
      getPageConfigList({
        brandId: this.$util.system.getBrandId(),
        pageName: pageName,
      }).then((res) => {
        if (res.success) {
          let data = res.data;
          this.pageData = res.data;
          this.savePage.form.pageName = data.pageName;
          this.savePage.form.pageTitle = data.pageTitleDraft || data.pageTitle;
          this.savePage.form.pageDescription = data.pageDescriptionDraft || data.pageDescription;
          this.savePage.form.pageKeyWords = data.pageKeyWordsDraft || data.pageKeyWords;
          if (data.pageName == '首頁') {
            this.savePage.form.type = 'menu'
          }
          let pageComs = data.pageValueDraft || data.pageValue;
          if (pageComs) {
            let coms = JSON.parse(pageComs);
            // console.log(coms);
            for (let i in coms) {
              if (coms[i])
                this.getComponent(coms[i]);
            }
          }
        }
      });
    },
    // 點擊單件
    clickItem (e, x, t) {
      // if (this.isMobile) return;
      console.log(e, x, t);
      // 拿到當前 最賤
      let item = this.coms[this.mouseoverIndex];
      // 克隆一份, 避免數據更改
      let editComs = this.$util.object.clone(item);
      // 重新加載 編輯組件
      editComs.component = () => import(`../components/edit/${item.key}.vue`);
      this.editComs = editComs;
      // 設置當前選中
      this.onRight.active = e;
      this.onRight.index = x;
      // if (t) {
      this.onRight.activeTabIndex = t;
      // }
      // 顯示編輯
      if (this.isMobile)
        this.editPosition = 'btt'
      this.onRight.drawer = true;
      // 刪除 編輯 效果
      // this.rightClose();
      // 添加 編輯效果
      let edit_dom = document.querySelector(
        `.edit_${this.mouseoverIndex}_${e}${x > -1 ? `_${x}` : ''}`
      );
      if (edit_dom) edit_dom.classList.add('on_edit');
    },
    // 左邊開始拖動
    onLeftStart () {
      // 開始拖動, 隱藏組件選擇
      this.onLeft.drawer = false;
    },
    // 左邊拖動結束
    onLeftEnd (e) {
      // 結束拖動, 設置組件
      this.getComponent(
        this.$util.object.clone(this.defaultComs[e.oldDraggableIndex])
      );
    },
    // 左邊停放
    onLeftMove (e, originalEvent) {
      // 不允許 左邊組件 互相換位
      return false;
    },
    // 鼠標移入
    onMouseover (e) {
      // 標識 當前項
      this.mouseoverIndex = e;
    },
    // 右鍵菜單
    onContextmenu (event) {
      // 拿到當前下標
      let index = this.mouseoverIndex;
      let coms = this.coms;
      this.$contextmenu({
        items: [
          {
            label: '內容上移',
            disabled: index == 0,
            onClick: () => {
              let a = coms[index];
              coms[index] = coms[index - 1];
              coms[index - 1] = a;
              this.coms = coms;
              this.$forceUpdate();
            },
          },
          {
            label: '內容下移',
            disabled: index + 1 == coms.length,
            onClick: () => {
              let a = coms[index];
              coms[index] = coms[index + 1];
              coms[index + 1] = a;
              this.coms = coms;
              this.$forceUpdate();
            },
          },
          {
            label: '刪除組件',
            onClick: () => {
              coms.splice(index, 1);
            },
          },
        ],
        event, // 鼠标事件信息
        customClass: 'custom-class', // 自定义菜单 class
        zIndex: 1003, // 菜单样式 z-index
        minWidth: 230, // 主菜单最小宽度
      });
      return false;
    },
    rightClose () {
      // 刪除 編輯 效果
      let edit_dom_ = document.querySelector('.on_edit');
      if (edit_dom_) {
        edit_dom_.classList.remove('on_edit');
      }
      this.editComs = {};
    },
    // 獲取 組件
    getComponent (event) {
      let coms = this.coms;
      let com = event;
      event.component = () => import(`../components/do/${event.key}.vue`);
      coms.push(com);
      this.coms = coms;
    },
    // 回顯 數據
    setValue (event) {
      console.log(event);
      let index = this.mouseoverIndex;
      this.coms[index].configs[event.key] = event.value;
      this.$forceUpdate();
    },
    // 新增某些 節點
    addItem (event) {
      let index = this.mouseoverIndex;
      let c_index = event.index;
      let item = event.item;
      let key = event.key;
      if (key) {
        this.coms[index].configs[key].splice(c_index + 1, 0, item);
      } else {
        this.coms[index].configs.splice(c_index + 1, 0, item);
      }
      this.coms[index].configs.initialIndex = Number(c_index) + 1;
      this.clickItem(
        this.onRight.active,
        Number(c_index) + 1,
        this.onRight.activeTabIndex
      );
    },
    delItem (event) {
      let index = this.mouseoverIndex;
      let c_index = event.index;
      let key = event.key;
      if (key) {
        this.coms[index].configs[key].splice(c_index, 1);
      } else {
        this.coms[index].configs.splice(c_index, 1);
      }
      // 刪除了當前的, 更改 選中
      this.coms[index].configs.initialIndex = c_index - 1;
      this.clickItem(
        this.onRight.active,
        c_index - 1,
        this.onRight.activeTabIndex
      );
    },
    onSave () {
      let coms = this.coms;
      if (coms.length == 0) {
        this.$message({
          message: '請先選擇組件',
          type: 'warning',
        });
      } else {
        // 這裡判斷 當前組件中 是否有本地文件, 如果有, 則不可保存
        // if (JSON.stringify(coms).includes('/official/')) {
        //   this.$message({
        //     message: '有資源是本地資源,請上傳更新後重試!',
        //     type: 'warning',
        //   });
        // } else
        this.savePage.dialogFormVisible = true;
      }
    },
    onMenu () {
      let coms = this.coms;
      if (coms.length > 0) {
        this.$confirm('尚有內容未保存,是否離開?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$router.replace({
              name: 'menu',
            });
          })
          .catch(() => { });
      } else {
        this.$router.replace({
          name: 'menu',
        });
      }
    },
    addPageFun (type) {
      let form = this.savePage.form;
      if (!form.pageName && form.type == 'default') {
        this.$message({
          message: '請輸入頁面名稱!',
          type: 'warning',
        });
      } else if (!form.pageTitle) {
        this.$message({
          message: '請輸入頁面標題!',
          type: 'warning',
        });
      } else {
        // 編輯邏輯
        let params = {};
        params.brandId = this.$util.system.getBrandId();
        params.pageName = form.type == 'menu' ? '首頁' : form.pageName;
        // * 頁面名稱 草稿
        params.pageNameDraft = form.type == 'menu' ? '首頁' : form.pageName;
        // * 頁面標題
        params.pageTitle = form.pageTitle;
        // * 頁面標題 草稿
        params.pageTitleDraft = form.pageTitle;
        // * 頁面描述 草稿
        params.pageDescriptionDraft = form.pageDescription;
        // * 頁面描述
        params.pageDescription = form.pageDescription;
        // * 頁面關鍵字 
        params.pageKeyWords = form.pageKeyWords;
        // * 頁面關鍵字 草稿
        params.pageKeyWordsDraft = form.pageKeyWordsDraft;
        // * 頁面數據 草稿
        params.pageValueDraft = JSON.stringify(this.coms);
        // * 是否發佈 => 未發佈
        params.release = this.pageData.release || '0';
        // * 域名
        params.domainName = location.host;
        params.pageType = 2;
        if (this.pageData.id) {
          params.id = this.pageData.id;
        }
        // 添加邏輯
        pageExists({
          brandId: this.$util.system.getBrandId(),
          pageName: params.pageName,
        }).then((res) => {
          this.savePage.dialogFormVisible = false;
          let id = '';
          if (res.data && res.data.length > 0) id = res.data[0];
          // 表示是否同一個
          if (!params.id && id == '') {
            addPage(params).then((res) => {
              if (res.success) {
                this.$message({
                  message: '新增成功！',
                  type: 'success',
                  onClose: () => {
                    if (type == 1) {
                      this.$router.replace({
                        name: 'menu',
                      });
                    } else {
                      this.$router.replace({
                        name: "preview",
                        params: {
                          arg: params.pageName
                        }
                      })
                    }

                  },
                });
              }
            });
          } else {
            updatePage(params).then((res) => {
              if (res.success) {
                this.$message({
                  message: '修改成功！',
                  type: 'success',
                  onClose: () => {
                    if (type == 1) {
                      this.$router.replace({
                        name: 'menu',
                      });
                    } else {
                      this.$router.replace({
                        name: "preview",
                        params: {
                          arg: params.pageName
                        }
                      })
                    }
                  },
                });
              } else {
                this.$message({
                  message: '修改失敗！',
                  type: 'warning',
                });
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-box {
  .px-left-menu-drawer {
    .title-box {
      display: flex;
      .title {
      }
      .menu {
        margin-left: auto;
        cursor: pointer;
        i {
          font-size: 24px;
        }
      }
    }
    .tgs_g {
      span {
        display: flex;
        flex-wrap: wrap;
      }
      .item-box {
        width: calc(50% - 20px);
      }
    }
    & /deep/ .el-drawer__body {
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(88, 88, 88, 0.2);
        background: #a09f9f;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
      }
    }
    .item-box {
      margin: 5px;
      padding: 5px;
      box-shadow: 0 1px 2px #ccc;
      border-radius: 3px;
      user-select: none;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &:hover {
        box-shadow: 0 1px 2px red;
        cursor: grab;
      }

      .image {
        vertical-align: middle;
      }
      .name {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  .px-left-menu {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0) !important;
    padding: 20px 5px;
    box-shadow: 0 2px 6px #ccc;
    border-radius: 0 5px 5px 0;
    z-index: 20;
    background-color: #fff;
  }
  .body-box {
    width: 100%;
    min-height: 100vh;
    .px-top-setting {
      height: 30px;
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #ededed;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 10px;
      div {
        &.welcome {
          margin-left: 0;
        }
        &.save {
          margin-left: 10px;
          cursor: pointer;
          &:hover {
            color: var(--themeColor);
          }
        }
        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }
    .edit_com_box {
      // position: relative;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px dashed #ccc;
        z-index: -1;
      }
    }
  }

  & /deep/ .on_edit {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 1px dashed #ccc;
    }
  }
  .px-edit-box {
    z-index: 5000 !important;
    & /deep/ .el-drawer__body {
      & > div:nth-child(1) {
        padding: 10px;
        h3 {
          margin: 10px 0;
          font-size: 16px;
        }
        h4 {
          margin-left: 5px;
          margin-bottom: 10px;
          font-size: 18px;
          position: sticky;
          top: 0;
          z-index: 10;
          width: 100%;
          background-color: #fff;
          color: var(--themeColor);
        }
        .h4 {
          position: sticky;
          top: 0;
          z-index: 10;
          width: 100%;
          background-color: #fff;
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            font-size: 18px;
            color: var(--themeColor);
            font-weight: 600;
          }
        }
      }
    }
    & /deep/ .el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
  }
}
</style>
